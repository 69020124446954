.fullscreen-widget-view {
    width: 100%;
    height: 100%;
}

.widget-fullscreen {
    margin: 0;
    height: 70vh;
    width: 100%;
}

.widget-fullscreen .widget-graph-container .widget-title-container {
    display: none;
}

.widget-fullscreen .widget-graph-container svg {
    width: 100%;
    position: relative;
}

.widget-fullscreen .widget-graph-container {
    display: block;
    height: 90%;
    flex: 0;
}

.widget-fullscreen .legend text,
.widget-fullscreen .axis text,
.widget-fullscreen .map text,
.widget-fullscreen .axis-text,
.widget-fullscreen .axis-label {
    fill: #000000;
}

.widget-fullscreen .widget-graph-container.highcharts-container .highcharts-axis-labels text,
.widget-fullscreen .widget-graph-container.highcharts-container .highcharts-legend text,
.widget-fullscreen .widget-graph-container.highcharts-container .highcharts-axis text {
    fill: #000000 !important;
}

.widget-fullscreen .widget-graph-container.highcharts-container div rect.highcharts-point {
    stroke: none !important;
}

.widget-fullscreen .widget-graph-container.highcharts-container div g.highcharts-yaxis path.highcharts-axis-line {
    stroke: none !important;
}

.nav-slider {
    height: 25px;
}

.widget-fullscreen .slick-dots li button:before {
    color: #fff !important;
    opacity: 0.5 !important;
}

.widget-fullscreen .slick-dots li button:focus:before,
.widget-fullscreen .slick-dots li button:hover:before {
    opacity: 0.5 !important;
}

.widget-fullscreen .slick-dots li.slick-active button:before {
    opacity: 1 !important;
}

.widget-fullscreen .slick-prev {
    left: 10px !important;
    z-index: 10;
}

.widget-fullscreen .slick-next {
    right: 10px !important;
}

.fullscreen-widget-title-div {
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 10px;
}

:root {
    /*Default slider width*/
    --thumb-width: 25px;
    --thumb-height: 10px;
    --gutter-height: 2px;
    --gutter-width: 90%;
}

input[type='range'] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    border: none;
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    height: var(--gutter-height);
    width: var(--gutter-width);
}

input[type='range']:focus {
    outline: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
    outline: none;
    background: linear-gradient(0deg, transparent 49%, rgb(173, 173, 173) 50%, transparent 51%);
}

input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: var(--gutter-height);
    background-color: transparent;
    background: linear-gradient(0deg, transparent 49%, rgb(173, 173, 173) 50%, transparent 51%);
    border: none;
    border-radius: 1px;
    padding: calc(var(--thumb-height) * 2) 0;
}

input[type='range']::-moz-range-track {
    width: 100%;
    height: var(--gutter-height);
    background-color: transparent;
    background: linear-gradient(0deg, transparent 49%, rgb(173, 173, 173) 50%, transparent 51%);
    border: none;
    border-radius: 1px;
    padding: calc(var(--thumb-height) * 2) 0;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
    height: 10px;
    width: var(--thumb-width);
    background: #fff;
    margin-top: -4px;
    border-radius: 5px;
}

input[type='range']::-moz-range-thumb {
    border: none;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
    height: 10px;
    width: var(--thumb-width);
    background: #fff;
    margin-top: -4px;
    border-radius: 5px;
}

/*hide the outline behind the border*/
input[type='range']:-moz-focusring {
    outline: none;
}
