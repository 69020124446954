@font-face {
    font-family: NunitoSans;
    src: url(./assets/fonts/NunitoSans-Regular.ttf);
}

@font-face {
    font-family: NunitoSans;
    src: url(./assets/fonts/NunitoSans-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: NunitoSans;
    src: url(./assets/fonts/NunitoSans-Light.ttf);
    font-weight: 200;
}

.pnl-fixTable thead tr:first-child th.category-header,
.widget-container table th {
    position: sticky;
    top: 0;
    border-top: none;
}

.pnl-fixTable thead tr:first-child th.category-header::after,
.widget-container table th::after,
.pnl-fixTable thead tr:first-child th.category-header::before,
.widget-container table th::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #999;
}

.pnl-fixTable thead tr:first-child th.category-header::before,
.widget-container table th::before {
    top: 0;
    right: 0;
}
.quill {
    height: 100% !important;
    width: 100% !important;
}
.ql-toolbar {
    height: 14%;
}
.ql-container {
    height: 86%;
}

/* sweet alert popup in front of mui dialog */
.swal2-container {
    z-index: 1500 !important;
}

html,
body {
    overflow: hidden !important;
    height: 100% !important;
}

input:focus {
    outline: none;
}

input {
    font-family: 'NunitoSans', sans-serif;
    font-weight: 400 !important;
}

h1 {
    font-size: 24px;
    margin: 10px 0;
}

h2 {
    font-size: 18px;
    margin: 10px 0;
    font-weight: normal;
}

h4 {
    font-size: 12px;
    margin: 10px 0;
}

a {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.App {
    text-align: center;
}

::-webkit-scrollbar {
    width: 16px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #828282;
    background-clip: padding-box;
    border: 3px solid transparent;
    min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
    border: none;
    border: 1px solid transparent;
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
    background: none;
    border: none;
}

.route-contents {
    height: 100%;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
    max-height: 180px;
}

/* Adding z-index to show column filters drop-down in the slide show mode*/
.ant-dropdown {
    z-index: 1500 !important;
}

.ant-table-column-sorters > span:first-child {
    display: flex;
}

/*highcharts-pivot is available for both Gaugeseires and SolidGauge. Hiding highcharts-pivot for Solid Gauge*/
.highcharts-solidgauge-series + .highcharts-gauge-series .highcharts-pivot {
    display: none;
}

/*Adding background color for SolidGauge tooltip and Vertical bar with Target chart tooltip*/
.highcharts-label-box.highcharts-tooltip-box {
    fill: #141414;
    stroke: #141414;
}

/* HACK - force tooltip text white */
.highcharts-tooltip {
    color: #fff !important;
}
.highcharts-tooltip > * {
    color: #fff !important;
}

/* Force snackbar icon color to match background */
.snackbar svg {
    color: inherit;
}

.slide-in-bottom {
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-out-bottom {
    animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out-bottom {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
